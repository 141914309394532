import * as React from "react";
import { Link } from "gatsby";

// import Bridge from "../../components/Bridge.js";
// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import Layout from "../../components/Layout";
import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";
import { graphql } from "gatsby";

export default function HardPlace({ data }) {
  return (
    <Layout>
      <Helmet title="HardPlace | H.E.R." />

      <h1>HardPlace</h1>

      <h2>H.E.R.</h2>

      <h4>Key: F#m</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Img fluid={data.file.childImageSharp.fluid} />

      <Columns columns="2">
        <Verse>
          <p>Wanna believe what you say</p>
          <p>But I hate you on most days</p>
          <p>You've been testing my faith and my patience, yeah</p>
        </Verse>
        <Verse>
          <p>And you know that I be headstrong</p>
          <p>But you know that you be dead wrong</p>
          <p>Telling me to relax when I'm reacting</p>
        </Verse>
        <PreChorus>
          <p>But I, I'd rather fight</p>
          <p>Than lose sleep at night</p>
          <p>At least you're all mine</p>
          <p>And if I have to choose</p>
          <p>My heart or you</p>
          <p>I'm gonna lose, yeah</p>
        </PreChorus>
        <Chorus>
          <p>What if nothing ever will change?</p>
          <p>Oh, I'm caught between your love and a hard place</p>
          <p>Oh, I wish there was a right way</p>
          <p>I'm caught between your love and a hard place, oh</p>
          <p>Oh, oh, oh, oh</p>
        </Chorus>
        <Verse>
          <p>Do I even have a choice when</p>
          <p>I'm gonna have to pick my poison?</p>
          <p>You hurt me so good, it's so good</p>
        </Verse>
        <Verse>
          <p>And even when you cause tears</p>
          <p>You're the one who wiped them away</p>
          <p>Maybe that's the reason I stay, I stay</p>
        </Verse>
        <PreChorus>
          <p>But I, I'd rather fight</p>
          <p>Than lose sleep at night</p>
          <p>At least you're all mine</p>
          <p>And if I have to choose</p>
          <p>My heart or you</p>
          <p>I'm gonna lose, yeah</p>
        </PreChorus>
        <Chorus>
          <p>What if nothing ever will change?</p>
          <p>Oh, I'm caught between your love and a hard place</p>
          <p>Oh, I wish there was a right way</p>
          <p>I'm caught between your love and a hard place, oh</p>
          <p>Oh, oh, oh, oh</p>
        </Chorus>
        <Chorus>
          <p>What if nothing ever will change?</p>
          <p>Oh, I'm caught between your love and a hard place</p>
          <p>Oh, I wish there was a right way</p>
          <p>I'm caught between your love and a hard place, oh</p>
          <p>Oh, oh, oh, oh</p>
        </Chorus>
        <Chorus>
          <p>What if nothing ever will change?</p>
          <p>Oh, I'm caught between your love and a hard place</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}

export const query = graphql`
  query {
    file(relativePath: { eq: "hard-place.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1600, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;